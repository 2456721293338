import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ChevronLeft, ChevronRight, Plus, Facebook, Instagram, Mail, Send, X, Menu } from 'react-feather';

import useWindowSize from './Hooks/useWindowSize';

import Home from './Screens/Home';
import Graficas from './Screens/Services/Graficas';
import Factibilidad from './Screens/Services/Factibilidad';
import Computo from './Screens/Services/Computo';
import Documentacion from './Screens/Services/Documentacion';
import Gestion from './Screens/Services/Gestion';
import Contact from './Screens/Contact';

import { MenuOverlay } from './Components/Menu';

import { CursorContext } from './Context/CursorContext';
import { MenuContext } from './Context/MenuContext';
import { WebPContext } from './Context/WebPContext';

import './App.css';

const Cursor = ({ cursorType }) => {

  const [position, setPosition] = useState({x: 0, y: 0});

  useEffect(() => {
    addEventListeners();
    return () => removeEventListeners();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e) => {
    setPosition({x: e.clientX, y: e.clientY});
  };     

  const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
  };
  
  if (typeof navigator !== 'undefined' && isMobile()) return null;
  
  return (
    <div className={`cursor ${cursorType}`} style={{ left: `${position.x}px`, top: `${position.y}px` }}>
      { cursorType === 'leftCursor'
          ? <ChevronLeft className="plusCursorIcon" style={{ opacity: cursorType === 'leftCursor' ? 1 : 0 }} />
          : cursorType === 'rightCursor'
            ? <ChevronRight className="plusCursorIcon" style={{ opacity: cursorType === 'rightCursor' ? 1 : 0 }} />
            : cursorType === 'fbCursor'
              ? <Facebook className="plusCursorIcon" style={{ opacity: cursorType === 'fbCursor' ? 1 : 0 }} />
              : cursorType === 'igCursor'
                ? <Instagram className="plusCursorIcon" style={{ opacity: cursorType === 'igCursor' ? 1 : 0 }} />
                : cursorType === 'mailCursor'
                  ? <Mail className="plusCursorIcon" style={{ opacity: cursorType === 'mailCursor' ? 1 : 0 }} />
                  : cursorType === 'sendCursor'
                    ? <Send className="plusCursorIcon" style={{ opacity: cursorType === 'sendCursor' ? 1 : 0 }} />
                    : cursorType === 'menuCursor'
                      ? <Menu className="plusCursorIcon" style={{ opacity: cursorType === 'menuCursor' ? 1 : 0 }} />
                      : cursorType === 'xCursor'
                        ? <X className="plusCursorIcon" style={{ opacity: cursorType === 'xCursor' ? 1 : 0 }} />
                        : <Plus className="plusCursorIcon" style={{ opacity: cursorType === 'plusCursor' ? 1 : 0 }} />
      }
    </div>
  );
}

const App = () => {

  const [cursorType, setCursorType] = useState('regularCursor');

  const [openMenu, setOpenMenu] = useState(false);

  const size = useWindowSize();

  const [webP, setWebP] = useState(null);

  useEffect(() => {
    const checkForWebP = async () => {
      if (!window.self.createImageBitmap) {
        setWebP('png');
        return false;
      }
      
      const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      const blob = await fetch(webpData).then(r => r.blob());
      const supportsWebP = await createImageBitmap(blob).then(() => true, () => false);
      
      setWebP(supportsWebP ? 'webp' : 'png');
    }

    checkForWebP();
  }, []);

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = `hidden`;
    } else {
      document.body.style.overflow = `visible`;
    }
  }, [openMenu]);

  return (
    <WebPContext.Provider value={{ webP: webP }}>
      <CursorContext.Provider value={{ setCursorType: setCursorType }}>
        <MenuContext.Provider value={{ setOpenMenu: setOpenMenu }}>
          <Router>
            <Cursor cursorType={cursorType} />

            <MenuOverlay open={openMenu} setOpen={setOpenMenu} webP={webP} />

            {/*<div id="innerApp" />*/}
            <Switch>
              <Route path="/administracion">
                <Gestion size={size} />
              </Route>
              <Route path="/documentacion">
                <Documentacion size={size} />
              </Route>
              <Route path="/computo">
                <Computo size={size} />
              </Route>
              <Route path="/factibilidad">
                <Factibilidad size={size} />
              </Route>
              <Route path="/graficas">
                <Graficas size={size} />
              </Route>
              <Route path="/contacto">
                <Contact size={size} />
              </Route>
              <Route path="/">
                <Home size={size} />
              </Route>
            </Switch>
          </Router>
        </MenuContext.Provider>
      </CursorContext.Provider>
    </WebPContext.Provider>
  );
}

export default App;
