import React, { useContext, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import { X } from 'react-feather';

import useWindowSize from '../Hooks/useWindowSize';

import { CursorContext } from '../Context/CursorContext';

import factibilidad from '../assets/images/factibilidad.jpg';
import graficas from '../assets/images/graficas.jpg';
import administracion from '../assets/images/administracion.jpg';
import documentacion from '../assets/images/documentacion.jpg';
import computo from '../assets/images/computo.jpg';

import webpFactibilidad from '../assets/images/webp/factibilidad.webp';
import webpGraficas from '../assets/images/webp/graficas.webp';
import webpAdministracion from '../assets/images/webp/administracion.webp';
import webpDocumentacion from '../assets/images/webp/documentacion.webp';
import webpComputo from '../assets/images/webp/computo.webp';

import './Menu.css';

const services = ['Gráficas de Arquitectura', 'Factibilidad de Proyectos', 'Cómputo y Presupuesto', 'Administración de Obra', 'Documentación Técnica'];

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const MenuOverlay = ({ open, setOpen, webP }) => {

  const history = useHistory();

  const size = useWindowSize();

  const { setCursorType } = useContext(CursorContext);

  const [index, setIndex] = useState(null);

  const closeMenu = () => {
    setOpen(false);

    sleep(500).then(() => {
      setCursorType('regularCursor');
    });
  }

  const hover = index => {
    setCursorType('noCursor');
    setIndex(index);
  }

  const unhover = () => {
    setCursorType('regularCursor');
    setIndex(null);
  }

  return (
    <motion.div 
      className="menuContainer" 
      initial={{ opacity: 0 }} 
      animate={{ opacity: open ? 1 : 0, transition: { duration: 0.7, ...transition } }}
      style={{ pointerEvents: open ? 'all' : 'none' }}
    >
      <div className="menuClose" onMouseEnter={() => setCursorType('xCursor')} onMouseLeave={() => setCursorType('regularCursor')} onClick={() => closeMenu()}>
        <X className="menuCloseIcon" />
      </div>
      { size.orientation === 'landscape' && <div className="menuTopLine" />}
      <AnimatePresence exitBeforeEnter>
        { size.orientation === 'landscape'
        ? <div className="menuInner">
            {webP ? <motion.img initial={{ opacity: 1 }} animate={{ opacity: index !== null && index !== 0 ? 0.3 : 1, transition: { duration: 0.5, ...transition } }} src={webP === 'webp' ? webpGraficas : graficas} alt={services[index]} className="menuButtonImg" onMouseEnter={() => hover(0)} onMouseLeave={() => unhover()} onClick={() => { history.push('/graficas'); closeMenu(); }} /> : null}
            {webP ? <motion.img initial={{ opacity: 1 }} animate={{ opacity: index !== null && index !== 1 ? 0.3 : 1, transition: { duration: 0.5, ...transition } }} src={webP === 'webp' ? webpFactibilidad : factibilidad} alt={services[index]} className="menuButtonImg" onMouseEnter={() => hover(1)} onMouseLeave={() => unhover()} style={{ marginTop: 70 }} onClick={() => { history.push('/factibilidad'); closeMenu(); }} /> : null}
            {webP ? <motion.img initial={{ opacity: 1 }} animate={{ opacity: index !== null && index !== 2 ? 0.3 : 1, transition: { duration: 0.5, ...transition } }} src={webP === 'webp' ? webpComputo : computo} alt={services[index]} className="menuButtonImg" onMouseEnter={() => hover(2)} onMouseLeave={() => unhover()} onClick={() => { history.push('/computo'); closeMenu(); }} /> : null}
            {webP ? <motion.img initial={{ opacity: 1 }} animate={{ opacity: index !== null && index !== 3 ? 0.3 : 1, transition: { duration: 0.5, ...transition } }} src={webP === 'webp' ? webpAdministracion : administracion} alt={services[index]} className="menuButtonImg" onMouseEnter={() => hover(3)} onMouseLeave={() => unhover()} style={{ marginTop: 70 }} onClick={() => { history.push('/administracion'); closeMenu(); }} /> : null}
            {webP ? <motion.img initial={{ opacity: 1 }} animate={{ opacity: index !== null && index !== 4 ? 0.3 : 1, transition: { duration: 0.5, ...transition } }} src={webP === 'webp' ? webpDocumentacion : documentacion} alt={services[index]} className="menuButtonImg" onMouseEnter={() => hover(4)} onMouseLeave={() => unhover()} onClick={() => { history.push('/documentacion'); closeMenu(); }} /> : null}

            <div className="menuBottom">
              
                <motion.div 
                  className="menuBottomText"
                  key={services[index]}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ exit: 0 }}
                  transition={{ duration: 0.3, ...transition }}
                >
                  {services[index]}
                </motion.div>
              <div className="menuBottomLine" />
            </div>
            <div className="menuContactLink" onClick={() => { history.push('/contacto'); closeMenu(); }} onMouseEnter={() => setCursorType('linkCursor')} onMouseLeave={() => setCursorType('regularCursor')}>Contacto</div>
          </div>
        : <div className="mobileMenuInner">
            <div className="mobileMenuSubtitle">Nuestros Servicios</div>
            <div className="mobileMenuLink" onClick={() => { history.push('/graficas'); closeMenu(); }}>Gráficas de Arquitectura</div>
            <div className="mobileMenuLink"onClick={() => { history.push('/factibilidad'); closeMenu(); }} >Factibilidad de Proyectos</div>
            <div className="mobileMenuLink" onClick={() => { history.push('/computo'); closeMenu(); }}>Cómputo y Presupuesto</div>
            <div className="mobileMenuLink" onClick={() => { history.push('/administracion'); closeMenu(); }}>Administración de Obra</div>
            <div className="mobileMenuLink" onClick={() => { history.push('/documentacion'); closeMenu(); }}>Documentación Técnica</div>
            <div className="mobileMenuSubtitle bottomMenuSubtitle" onClick={() => { history.push('/contacto'); closeMenu(); }}>Contacto</div>
          </div>
        }
      </AnimatePresence>
    </motion.div>
  );

};

export { MenuOverlay };