import React from 'react';

import './FormTextInput.css';

function AltFormTextInput({ placeholder, value, onChange, color = false, correct, incorrect, password = false }) {

  return (
    <div className="formInputSimpleContainer">
      <label htmlFor={placeholder} style={{ opacity: 0 }}>{placeholder}</label>
      <p id={placeholder} className="formInputSimpleLabel" style={{ color: 'rgba(43, 87, 124, 0.7)' }}>{placeholder}</p>
      <input type={password ? "password" : "text"} style={{ color: '#2B577C', borderBottomColor: 'rgba(43, 87, 124, 0.7)' }} className="formInputSimple" placeholder="" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}

export { AltFormTextInput };