import React, { useContext } from 'react';

import { Facebook, Instagram, Mail } from 'react-feather';

import { CursorContext } from '../Context/CursorContext';

import './Footer.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Footer = ({ color }) => {

  const { setCursorType } = useContext(CursorContext);

  const handleClick = (action) => {
    if (action === 'mail') {
      window.open('mailto:contacto@obliquo.co');
    } else if (action === 'facebook') {
      window.open('https://www.instagram.com/obliquoarq/?hl=es-la', '_blank');
    } else {
      window.open('https://www.instagram.com/obliquoarq/?hl=es-la', '_blank');
    }

    sleep(500).then(() => {
      setCursorType('regularCursor');
    });
  }

  return (
    <div className="homeFooterContainer" style={{ backgroundColor: color ? '#fafafa' : '#2B577C' }}>
      <div className="homeFooterInnerContainer" style={{ borderTopColor: color ? '#2B577C' : '#d1d1d1' }}>
        <div className="homeFooterIcons">
          <div className="homeFooterButton" onClick={() => handleClick('facebook')} onMouseEnter={() => setCursorType('fbCursor')} onMouseLeave={() => setCursorType('regularCursor')} style={{ borderColor: color ? '#2B577C' : '#d1d1d1' }}>
            <Facebook className="homeFooterButtonIcon" style={{ color: color ? '#2B577C' : '#d1d1d1' }} />
          </div>
          <div className="homeFooterButton" onClick={() => handleClick('instagram')} onMouseEnter={() => setCursorType('igCursor')} onMouseLeave={() => setCursorType('regularCursor')} style={{ borderColor: color ? '#2B577C' : '#d1d1d1' }}>
            <Instagram className="homeFooterButtonIcon" style={{ color: color ? '#2B577C' : '#d1d1d1' }} />
          </div>
          <div className="homeFooterButton" onClick={() => handleClick('mail')} onMouseEnter={() => setCursorType('mailCursor')} onMouseLeave={() => setCursorType('regularCursor')} style={{ borderColor: color ? '#2B577C' : '#d1d1d1' }}>
            <Mail className="homeFooterButtonIcon" style={{ color: color ? '#2B577C' : '#d1d1d1' }} />
          </div>
        </div>
        <p className="homeFooterText" style={{ color: color ? '#2B577C' : '#d1d1d1' }}>2020 © Obliquo Arquitectura Inmobiliaria</p>
      </div>
    </div>
  );

};

export { Footer };