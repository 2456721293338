import React from 'react';

import './FormTextArea.css';

function FormTextArea(props) {

  return (
    <div className="formInputSimpleContainer">
      <label htmlFor={props.placeholder} style={{ opacity: 0 }}>{props.placeholder}</label>
      <p id={props.placeholder} className="formInputSimpleLabel">{props.placeholder}</p>
      <textarea className="formTextArea" placeholder="" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    </div>
  );
}

export { FormTextArea };