import React, { useEffect, useRef, useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import { ChevronRight, ChevronLeft, Send, X, Check, MoreHorizontal } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';
import { FormTextArea } from '../Components/FormTextArea';
import { FormTextInput } from '../Components/FormTextInput';

import header from '../assets/images/mainTitle.png';
import headerImg from '../assets/images/header2.jpg';
import about from '../assets/images/about.jpg';
import skyline from '../assets/images/skyline.png';
import factibilidad from '../assets/images/factibilidad.jpg';
import graficas from '../assets/images/graficas.jpg';
import administracion from '../assets/images/administracion.jpg';
import documentacion from '../assets/images/documentacion.jpg';
import computo from '../assets/images/computo.jpg';

import webpHeader from '../assets/images/webp/mainTitle.webp';
import webpHeaderImg from '../assets/images/webp/header2.webp';
import webpAbout from '../assets/images/webp/about.webp';
import webpSkyline from '../assets/images/webp/skyline.webp';
import webpFactibilidad from '../assets/images/webp/factibilidad.webp';
import webpGraficas from '../assets/images/webp/graficas.webp';
import webpAdministracion from '../assets/images/webp/administracion.webp';
import webpDocumentacion from '../assets/images/webp/documentacion.webp';
import webpComputo from '../assets/images/webp/computo.webp';

import mobileHeader from '../assets/images/mobile/mainTitle.png';
import mobileHeaderImg from '../assets/images/mobile/header2.jpg';
import mobileAbout from '../assets/images/mobile/about.jpg';
import mobileSkyline from '../assets/images/mobile/skyline.png';
import mobileFactibilidad from '../assets/images/mobile/factibilidad.jpg';
import mobileGraficas from '../assets/images/mobile/graficas.jpg';
import mobileAdministracion from '../assets/images/mobile/administracion.jpg';
import mobileDocumentacion from '../assets/images/mobile/documentacion.jpg';
import mobileComputo from '../assets/images/mobile/computo.jpg';

import { CursorContext } from '../Context/CursorContext';
import { WebPContext } from '../Context/WebPContext';

import './Home.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const carouselTransition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] };

const carouselKeys = ['graficas', 'factibilidad', 'computo', 'administracion', 'documentacion'];

const Home = ({ size }) => {

  const { setCursorType } = useContext(CursorContext);

  const { webP } = useContext(WebPContext);

  const history = useHistory();
  const location = useLocation();

  const [exit, setExit] = useState(false);

  const frameID = useRef();
  const scrollContainer = useRef();

  const [carouselIndex, setCarouselIndex] = useState(0);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [formStatus, setFormStatus] = useState('none');

  //const [scrollPosition, setScrollPosition] = useState(0);

  /*useEffect(() => {
    const onScroll = e => {
      setScrollPosition(document.getElementById('root').scrollTop);
    }

    document.getElementById('root').addEventListener('scroll', onScroll);

    return () => document.getElementById('root').removeEventListener('scroll', onScroll);
  }, []);*/

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBodyHeight();
  }, [size.height, webP]);

  const setBodyHeight = () => {
    sleep(1000).then(() => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`;
    })
  };

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0
  };

  const skewScrolling = () => {
    if (window.location.pathname !== '/') {
      cancelAnimationFrame(frameID.current);
      return true;
    }

    //data.current = document.getElementById('root').scrollTop;
    data.current = window.scrollY;

    //Set Current to the scroll position amount
    //data.current = window.scrollY;
    // Set Previous to the scroll previous position
    data.previous += (data.current - data.previous) * data.ease;
    // Set rounded to
    data.rounded = Math.round(data.previous * 100) / 100;

    // Difference between
    //const difference = data.current - data.rounded;
    //const acceleration = difference / size.width;
    //const velocity = +acceleration;
    //const skew = velocity * 7.5;

    //Assign skew and smooth scrolling to the scroll container
    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0)`;
    }

    //loop vai raf
    frameID.current = requestAnimationFrame(() => skewScrolling());
  };

  const goTo = path => {
    if (location.pathname !== path) {
      setExit(true);

      sleep(1000).then(() => {
        setCursorType('regularCursor');
        window.scrollTo(0, 0);
        history.push(path);
      });
    }
  }

  const handleSubmit = () => {
    if (formStatus === 'sent') {
      setName('');
      setEmail('');
      setMessage('');
      setFormStatus('none');
      return;
    } else if (formStatus === 'error') {
      setFormStatus('none');
      return;
    }

    setFormStatus('sending');

    axios({
      method:'post',
      url: "https://us-central1-obliquo-front.cloudfunctions.net/contactFormSubmitted",
      data: {
        name: name,
        email: email,
        message: message
      }
    })
      .then((response) => {
        setFormStatus('sent');
      })
      .catch((error) => {
        setFormStatus('error');
      });
  }

  return (
    <div className="homeContainer">
      
      <motion.div 
        className="inTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: size.height }}
        animate={{ height: 0 }}
        transition={transition}
      />

      <motion.div 
        className="outTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: 0 }}
        animate={{ height: exit ? size.height : 0 }}
        transition={transition}
      />

      <div ref={scrollContainer} className="categoryInnerContainer">
        <Navbar size={size} goTo={goTo} />
        <div className="headerContainer">
          {webP ? <img src={size.mobile ? mobileHeader : webP === 'webp' ? webpHeader : header} alt="ARQ." className="headerMain" /> : null}
          <div className="headerSub">“El secreto del éxito no está en hacer el trabajo uno mismo, sino en reconocer a la mejor persona para hacerlo.”<br/><br/>- Andrew Carnegie</div>
        </div>
        {webP ? <img src={size.mobile ? mobileHeaderImg : webP === 'webp' ? webpHeaderImg : headerImg} alt="Encabezado" className="headerImg" /> : null}
        
          <div className="carouselContainer" onMouseEnter={() => setCursorType('plusCursor')} onMouseLeave={() => setCursorType('regularCursor')} onClick={() => goTo(carouselKeys[carouselIndex])}>
            <div className="carouselTextContainer">
              <div className="carouselTitleContainer">
                <AnimatePresence>
                  <motion.p key={carouselKeys[carouselIndex]} initial={{ y: 300 }} animate={{ y: 0 }} exit={{ y: -300 }} transition={carouselTransition} className="carouselTitle">
                    {carouselIndex === 0 ? "Gráficas de Arquitectura" : carouselIndex === 1 ? "Factibilidad de Proyectos" : carouselIndex === 2 ? "Cómputo y Presupuesto" : carouselIndex === 3 ? "Administración de Obra" : "Documentación Técnica"}
                  </motion.p>
                </AnimatePresence>
              </div>
              <AnimatePresence>
                <motion.div key={carouselKeys[carouselIndex] + '_text'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={carouselTransition} className="carouselTextInnerContainer">
                  {carouselIndex === 0 
                  ? <p className="carouselText">
                      Los planos, esquemas, modelos y demás elementos gráficos en arquitectura ayudan tanto a entender una idea como también así concretarla. 
                      <br/><br/>La importancia de los mismos en un proyecto es vital tanto para el desarrollo, la materialización o la comercialización.
                      <br/><br/><span className="carouselTextClick">Clickea para ver más</span>
                    </p>
                  : carouselIndex === 1 
                    ? <p className="carouselText">
                        Un proyecto de arquitectura tiene una cantidad innumerable de piezas en movimiento que deben encajar a la perfección.
                        <br/><br/>Desde una perspectiva objetiva, intentamos analizar dichos proyectos para que cumplan de la mejor manera posible con las limitaciones y reglamentaciones existentes.
                        <br/><br/><span className="carouselTextClick">Clickea para ver más</span>
                      </p>
                    : carouselIndex === 2
                      ? <p className="carouselText">
                          Tanto el cómputo como el presupuesto son dos elementos fundamentales para conocer un proyecto de arquitectura en profundidad. Permite conocer cantidades, costos y posibles beneficios, que ayudarán a moldear el resultado hacia el objetivo deseado.
                          <br/><br/>La importancia del rigor a la hora de confeccionar estos dos ítems, son fundamentales para que las conclusiones extraídas sean lo más verosímiles y exactas posibles.
                          <br/><br/><span className="carouselTextClick">Clickea para ver más</span>
                        </p>
                      : carouselIndex === 3
                        ? <p className="carouselText">
                            La administración de obra es una tarea fundamental para llegar a los objetivos planteados por los proyectistas. Es importante que exista cierto control de las tareas y sus procesos para evitar demoras y problemas en el futuro.
                            <br/><br/><span className="carouselTextClick">Clickea para ver más</span>
                          </p>
                        : <p className="carouselText">
                            Una característica fundamental para el buen desarrollo de los proyectos es una comunicación clara. La mejor manera de lograrlo es mediante una documentación técnica legible y comprensible, tanto para el cliente como para los organismos competentes donde la misma ha de ser presentada.
                            <br/><br/><span className="carouselTextClick">Clickea para ver más</span>
                          </p>
                  }
                </motion.div>
              </AnimatePresence>
              <div className="carouselControlBtnsContainer">
                <div className="carouselControlBtn" onMouseEnter={() => setCursorType('noCursor')} onMouseLeave={() => setCursorType('plusCursor')} onClick={e => { e.stopPropagation(); setCarouselIndex(i => i === 0 ? 4 : i - 1); }}>
                  <ChevronLeft className="carouselControlIcon" />
                </div>
                <div className="carouselControlBtn" onMouseEnter={() => setCursorType('noCursor')} onMouseLeave={() => setCursorType('plusCursor')} onClick={e => { e.stopPropagation(); setCarouselIndex(i => i === 4 ? 0 : i + 1); }}>
                  <ChevronRight className="carouselControlIcon" />
                </div>
              </div>
            </div>
            <div className="carouselImgContainer">
              <AnimatePresence>
                {webP ? <motion.img 
                  key={carouselKeys[carouselIndex] + '_img'} 
                  src={carouselIndex === 0 ? (size.mobile ? mobileGraficas : webP === 'webp' ? webpGraficas : graficas) : carouselIndex === 1 ? (size.mobile ? mobileFactibilidad : webP === 'webp' ? webpFactibilidad : factibilidad) : carouselIndex === 2 ? (size.mobile ? mobileComputo : webP === 'webp' ? webpComputo : computo) : carouselIndex === 3 ? (size.mobile ? mobileAdministracion : webP === 'webp' ? webpAdministracion : administracion) : (size.mobile ? mobileDocumentacion : webP === 'webp' ? webpDocumentacion : documentacion)}
                  initial={{ opacity: 0, scale: 1.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 1.2 }} 
                  transition={{ ...carouselTransition, duration: 0.5 }} 
                  alt={carouselKeys[carouselIndex]} 
                  className="carouselImg" 
                /> : null}
              </AnimatePresence>
            </div>
          </div>
        
        <div className="aboutContainer">
          <div className="aboutImgContainer">
            {webP ? <img src={size.mobile ? mobileAbout : webP === 'webp' ? webpAbout : about} alt="Sobre Nosotros" className="aboutImg" /> : null}
          </div>
          <div className="aboutTextContainer">
            <div className="aboutText">
            Somos un equipo de profesionales multidisciplinarios con especialidades en distintas áreas. Esto nos permite encarar todos nuestros proyectos desde distintos puntos de vista y darles el enfoque exclusivo que cada uno requiere.
            <br/><br/>Entender la arquitectura desde un punto de vista holístico es fundamental para que todos los engranajes que integran la misma funcionen a la perfección. En muchos casos un arquitecto oficia como un director de orquesta, debe conocer y entender todos los elementos que componen al proyecto de forma que su conjunción sea lo mas armónica posible.
            <br/><br/>Nuestra misión es entender las necesidades de cada cliente y completar los vacíos en el proyecto en base a nuestra experiencia para lograr un resultado satisfactorio. Buscamos superar los objetivos propuestos, mejorando a lo largo del tiempo.
            </div>
            <div className="aboutTitleContainer">
              <div className="aboutSub">¿Quiénes somos?</div>
              <div className="aboutTitle">Conocenos</div>
            </div>
          </div>
        </div>

        {webP ? <img src={size.mobile ? mobileSkyline : webP === 'webp' ? webpSkyline : skyline} alt="Skyline" className="contactSkyline" /> : null}

        <div className="contactContainer">
          <div className="contactTextContainer">
            <p className="contactTitle">Envianos tu consulta</p>
            <p className="contactSubtitle">Dejanos un mensaje o contactanos a través de nuestras redes para asistirte con lo que necesites.</p>
          </div>
          <div className="contactFormContainer">
            <FormTextInput placeholder="Nombre" value={name} onChange={setName} color='#fafafa' />
            <FormTextInput placeholder="Email" value={email} onChange={setEmail} color='#fafafa' />
            <FormTextArea placeholder="Mensaje" value={message} onChange={setMessage} color='#fafafa' />
            <motion.div transition={carouselTransition} initial={{ backgroundColor: '#fafafa' }} animate={{ backgroundColor: formStatus === 'sent' ? '#63d176' : formStatus === 'error' ? '#fc3d39' : '#fafafa' }} className="contactFormButton" style={{ opacity: (name === '' || email === '' || message === '') && formStatus === 'none' ? 0.5 : 1 }} onClick={name === '' || email === '' || message === '' || formStatus === 'sending' ? null : () => handleSubmit()} onMouseEnter={() => setCursorType('noCursor')} onMouseLeave={() => setCursorType('regularCursor')}>
              {formStatus === 'none' ? <Send className="contactFormButtonIcon" /> : formStatus === 'sending' ? <MoreHorizontal className="contactFormButtonIcon" /> : formStatus === 'sent' ? <Check className="contactFormButtonIcon" /> : <X className="contactFormButtonIcon" />}
            </motion.div>
          </div>
        </div>
        
        <Footer color={false} />

      </div>
    </div>
  );
};

export default Home;