import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';

import { Navbar } from '../../Components/Navbar';
import { Footer } from '../../Components/Footer';
import { ContactBanner } from '../../Components/ContactBanner';

import headerImg from '../../assets/images/graficas.jpg';
import webpHeaderImg from '../../assets/images/webp/graficas.webp';
import mobileHeaderImg from '../../assets/images/mobile/graficas.jpg';

import { CursorContext } from '../../Context/CursorContext';
import { WebPContext } from '../../Context/WebPContext';

import './Graficas.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] };

const Graficas = ({ size }) => {

  const { setCursorType } = useContext(CursorContext);

  const { webP } = useContext(WebPContext);

  const history = useHistory();
  const location = useLocation();

  const [exit, setExit] = useState(false);

  const frameID = useRef();
  const scrollContainer = useRef();

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBodyHeight();
  }, [size.height, webP]);

  const setBodyHeight = () => {
    sleep(1000).then(() => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`;
    })
  };

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0
  };

  const skewScrolling = () => {
    if (window.location.pathname !== '/graficas') {
      cancelAnimationFrame(frameID.current);
      return true;
    }

    //data.current = document.getElementById('root').scrollTop;
    data.current = window.scrollY;

    //Set Current to the scroll position amount
    //data.current = window.scrollY;
    // Set Previous to the scroll previous position
    data.previous += (data.current - data.previous) * data.ease;
    // Set rounded to
    data.rounded = Math.round(data.previous * 100) / 100;

    // Difference between
    //const difference = data.current - data.rounded;
    //const acceleration = difference / size.width;
    //const velocity = +acceleration;
    //const skew = velocity * 7.5;

    //Assign skew and smooth scrolling to the scroll container
    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0)`;
    }

    //loop vai raf
    frameID.current = requestAnimationFrame(() => skewScrolling());
  };

  const goTo = path => {
    if (location.pathname !== path) {
      setExit(true);

      sleep(1000).then(() => {
        setCursorType('regularCursor');
        window.scrollTo(0, 0);
        history.push(path);
      });
    }
  }

  return (
    <div className="homeContainer">

      <motion.div 
        className="inTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: size.height }}
        animate={{ height: 0 }}
        transition={transition}
      />

      <motion.div 
        className="outTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: 0 }}
        animate={{ height: exit ? size.height : 0 }}
        transition={transition}
      />

      <div ref={scrollContainer} className="categoryInnerContainer">
        <Navbar size={size} goTo={goTo} />
        
        <div className="graficasSubheader">
          <p className="graficasSubheaderAuxText">Desarrollamos tus conceptos de forma gráfica para mejorar su comprensión y su posterior uso.</p>
          <p className="graficasSubheaderText">GRÁFICAS DE</p>
        </div>
        <div className="graficasHeader">
          <p className="graficasHeaderText">ARQUITECTURA</p>
        </div>

        {webP ? <img src={size.mobile ? mobileHeaderImg : webP === 'webp' ? webpHeaderImg : headerImg} alt="Encabezado" className="graficasHeaderImg" /> : null}

        <p className="graficasAuxText">Los planos, esquemas, modelos y demás elementos gráficos en arquitectura ayudan tanto a entender una idea como también así concretarla. La importancia de los mismos en un proyecto es vital tanto para el desarrollo, la materialización o la comercialización.</p>

        <div className="stepContainer">
          <p className="stepNumber">01</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Concepto</div>
            <div className="stepText">Nuestro primer objetivo es entender los conceptos más puros a transmitir por el cliente para lograr plasmarlos y comunicarlos de la mejor manera.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">02</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Estrategia</div>
            <div className="stepText">Una vez comprendido dichos conceptos, analizaremos diversas estrategias que logren comunicar las ideas del cliente.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">03</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Ejecución</div>
            <div className="stepText">Ejecutaremos lo planteado compartiéndolo con el cliente buscando que el mismo participe en todo momento del proceso, logrando que los resultados sean lo mas satisfactorios posibles.</div>
          </div>
        </div>

        <ContactBanner goTo={goTo} />

        <Footer color={true} />
      </div>
    </div>
  );
};

export default Graficas;