import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';

import { Navbar } from '../../Components/Navbar';
import { Footer } from '../../Components/Footer';
import { ContactBanner } from '../../Components/ContactBanner';

import headerImg from '../../assets/images/administracion.jpg';
import webpHeaderImg from '../../assets/images/webp/administracion.webp';
import mobileHeaderImg from '../../assets/images/mobile/administracion.jpg';

import { CursorContext } from '../../Context/CursorContext';
import { WebPContext } from '../../Context/WebPContext';

import './Gestion.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] };

const Gestion = ({ size }) => {

  const { setCursorType } = useContext(CursorContext);

  const { webP } = useContext(WebPContext);

  const history = useHistory();
  const location = useLocation();

  const [exit, setExit] = useState(false);

  const frameID = useRef();
  const scrollContainer = useRef();

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBodyHeight();
  }, [size.height, webP]);

  const setBodyHeight = () => {
    sleep(1000).then(() => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`;
    })
  };

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0
  };

  const skewScrolling = () => {
    if (window.location.pathname !== '/administracion') {
      cancelAnimationFrame(frameID.current);
      return true;
    }

    //data.current = document.getElementById('root').scrollTop;
    data.current = window.scrollY;

    //Set Current to the scroll position amount
    //data.current = window.scrollY;
    // Set Previous to the scroll previous position
    data.previous += (data.current - data.previous) * data.ease;
    // Set rounded to
    data.rounded = Math.round(data.previous * 100) / 100;

    // Difference between
    //const difference = data.current - data.rounded;
    //const acceleration = difference / size.width;
    //const velocity = +acceleration;
    //const skew = velocity * 7.5;

    //Assign skew and smooth scrolling to the scroll container
    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0)`;
    }

    //loop vai raf
    frameID.current = requestAnimationFrame(() => skewScrolling());
  };

  const goTo = path => {
    if (location.pathname !== path) {
      setExit(true);

      sleep(1000).then(() => {
        setCursorType('regularCursor');
        window.scrollTo(0, 0);
        history.push(path);
      });
    }
  }

  return (
    <div className="homeContainer">

      <motion.div 
        className="inTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: size.height }}
        animate={{ height: 0 }}
        transition={transition}
      />

      <motion.div 
        className="outTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: 0 }}
        animate={{ height: exit ? size.height : 0 }}
        transition={transition}
      />

      <div ref={scrollContainer} className="categoryInnerContainer">
        <Navbar size={size} goTo={goTo} />


        <div className="documentacionHeader">
          <p className="documentacionHeaderText">ADMINISTRACIÓN</p>
        </div>
        <div className="documentacionSubheader">
          <p className="documentacionSubheaderAuxText">Aseguramos un seguimiento constante para el avance fluido en las distintas etapas de tu obra. </p>
          <p className="documentacionSubheaderText">DE OBRA</p>
        </div>

        {webP ? <img src={size.mobile ? mobileHeaderImg : webP === 'webp' ? webpHeaderImg : headerImg} alt="Encabezado" className="graficasHeaderImg" /> : null}

        <p className="graficasAuxText">
          La administración de obra es una tarea fundamental para llegar a los objetivos planteados por los proyectistas. Es importante que exista cierto control de las tareas y sus procesos para evitar demoras y problemas en el futuro.
        </p>

        <div className="stepContainer">
          <p className="stepNumber">01</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Estudio</div>
            <div className="stepText">Comenzaremos estudiando el proyecto y las tareas a desarrollar en el mismo.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">02</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Optimización</div>
            <div className="stepText">Buscaremos optimizar de la mejor manera posibles estas labores logrando que los gremios puedan realizar sus trabajos de la mejor manera posible, evitando demoras innecesarias.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">03</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Plan</div>
            <div className="stepText">Desarrollaremos un plan que comunicaremos a los distintos gremios.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">04</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Seguimiento</div>
            <div className="stepText">Realizaremos controles periódicos de las obras tanto para verificar el seguimiento del proceso del trabajo establecido, como así también, para proponer soluciones a inconvenientes que surjan a lo largo de la misma.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">05</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Documentación</div>
            <div className="stepText">Al finalizar la obra se le entregará al cliente una serie de esquemas y documentos que expliquen lo desarrollado en la misma.</div>
          </div>
        </div>

        <ContactBanner goTo={goTo} />

        <Footer color={true} />
      </div>
    </div>
  );
};

export default Gestion;