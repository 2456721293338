import React, { useContext } from 'react';

import { ArrowRight } from 'react-feather';

import { CursorContext } from '../Context/CursorContext';

import './ContactBanner.css';

const ContactBanner = ({ goTo }) => {

  const { setCursorType } = useContext(CursorContext);

  return (
    <div className="contactBannerContainer">
      <div className="contactBannerTextContainer">
        <div className="contactBannerTitle">Hablemos</div>
        <div className="contactBannerText">Nos gustaría saber más sobre vos y ver en que podemos ayudarte. <br/>Comunicate con nosotros.</div>
      </div>
      <div className="contactBannerButton" onMouseEnter={() => setCursorType('noCursor')} onMouseLeave={() => setCursorType('regularCursor')} onClick={() => goTo('/contacto')}>
        <ArrowRight className="contactBannerButtonIcon" />
      </div>
    </div>
  );

};

export { ContactBanner };