import React from 'react';

import './FormTextInput.css';

function FormTextInput({ placeholder, value, onChange, color = false, correct, incorrect, password = false }) {

  return (
    <div className="formInputSimpleContainer">
      <label htmlFor={placeholder} style={{ opacity: 0 }}>{placeholder}</label>
      <p id={placeholder} className="formInputSimpleLabel">{placeholder}</p>
      <input type={password ? "password" : "text"} className="formInputSimple" placeholder="" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}

export { FormTextInput };