import React from 'react';

import './FormTextArea.css';

function AltFormTextArea(props) {

  return (
    <div className="formInputSimpleContainer">
      <label htmlFor={props.placeholder} style={{ opacity: 0 }}>{props.placeholder}</label>
      <p id={props.placeholder} className="formInputSimpleLabel" style={{ color: 'rgba(43, 87, 124, 0.7)' }}>{props.placeholder}</p>
      <textarea className="formTextArea" style={{ color: '#2B577C', borderBottomColor: 'rgba(43, 87, 124, 0.7)' }} placeholder="" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    </div>
  );
}

export { AltFormTextArea };