import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import { Send, X, Check, MoreHorizontal } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';
import { AltFormTextArea } from '../Components/AltFormTextArea';
import { AltFormTextInput } from '../Components/AltFormTextInput';

import { CursorContext } from '../Context/CursorContext';

import './Contact.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] };

const carouselTransition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const Contact = ({ size }) => {

  const { setCursorType } = useContext(CursorContext);

  const history = useHistory();
  const location = useLocation();

  const [exit, setExit] = useState(false);

  const [formStatus, setFormStatus] = useState('none');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const frameID = useRef();
  const scrollContainer = useRef();

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBodyHeight();
  }, [size.height]);

  const setBodyHeight = () => {
    sleep(1000).then(() => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`;
    })
  };

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0
  };

  const skewScrolling = () => {
    if (window.location.pathname !== '/contacto') {
      cancelAnimationFrame(frameID.current);
      return true;
    }

    //data.current = document.getElementById('root').scrollTop;
    data.current = window.scrollY;

    //Set Current to the scroll position amount
    //data.current = window.scrollY;
    // Set Previous to the scroll previous position
    data.previous += (data.current - data.previous) * data.ease;
    // Set rounded to
    data.rounded = Math.round(data.previous * 100) / 100;

    // Difference between
    //const difference = data.current - data.rounded;
    //const acceleration = difference / size.width;
    //const velocity = +acceleration;
    //const skew = velocity * 7.5;

    //Assign skew and smooth scrolling to the scroll container
    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0)`;
    }

    //loop vai raf
    frameID.current = requestAnimationFrame(() => skewScrolling());
  };

  const goTo = path => {
    if (location.pathname !== path) {
      setExit(true);

      sleep(1000).then(() => {
        setCursorType('regularCursor');
        window.scrollTo(0, 0);
        history.push(path);
      });
    }
  }

  const handleSubmit = () => {
    if (formStatus === 'sent') {
      setName('');
      setEmail('');
      setMessage('');
      setFormStatus('none');
      return;
    } else if (formStatus === 'error') {
      setFormStatus('none');
      return;
    }

    setFormStatus('sending');

    axios({
      method:'post',
      url: "https://us-central1-obliquo-front.cloudfunctions.net/contactFormSubmitted",
      data: {
        name: name,
        email: email,
        message: message
      }
    })
      .then((response) => {
        setFormStatus('sent');
      })
      .catch((error) => {
        setFormStatus('error');
      });
  }

  return (
    <div className="homeContainer">

      <motion.div 
        className="inTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: size.height }}
        animate={{ height: 0 }}
        transition={transition}
      />

      <motion.div 
        className="outTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: 0 }}
        animate={{ height: exit ? size.height : 0 }}
        transition={transition}
      />

      <div ref={scrollContainer} className="categoryInnerContainer">
        <Navbar size={size} goTo={goTo} />

        <div className="contactPageContainer">
          <div className="contactTextContainer">
            <p className="contactTitle" style={{ color: '#2B577C' }}>Envianos tu consulta</p>
            <p className="contactSubtitle" style={{ color: '#2B577C' }}>Dejanos un mensaje o contactanos a través de nuestras redes para asistirte con lo que necesites.</p>
          </div>
          <div className="contactFormContainer">
            <AltFormTextInput placeholder="Nombre" value={name} onChange={setName} color='#fafafa' />
            <AltFormTextInput placeholder="Email" value={email} onChange={setEmail} color='#fafafa' />
            <AltFormTextArea placeholder="Mensaje" value={message} onChange={setMessage} color='#fafafa' />
            <motion.div transition={carouselTransition} initial={{ backgroundColor: '#2B577C' }} animate={{ backgroundColor: formStatus === 'sent' ? '#63d176' : formStatus === 'error' ? '#fc3d39' : '#2B577C' }} className="contactFormButton" style={{ opacity: (name === '' || email === '' || message === '') && formStatus === 'none' ? 0.5 : 1 }} onClick={name === '' || email === '' || message === '' || formStatus === 'sending' ? null : () => handleSubmit()} onMouseEnter={() => setCursorType('noCursor')} onMouseLeave={() => setCursorType('regularCursor')}>
              {formStatus === 'none' ? <Send style={{ color: '#fafafa' }} className="contactFormButtonIcon" /> : formStatus === 'sending' ? <MoreHorizontal style={{ color: '#fafafa' }} className="contactFormButtonIcon" /> : formStatus === 'sent' ? <Check style={{ color: '#fafafa' }} className="contactFormButtonIcon" /> : <X style={{ color: '#fafafa' }} className="contactFormButtonIcon" />}
            </motion.div>
          </div>
        </div>

        <Footer color={true} />
      </div>
    </div>
  );
};

export default Contact;