import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';

import { Navbar } from '../../Components/Navbar';
import { Footer } from '../../Components/Footer';
import { ContactBanner } from '../../Components/ContactBanner';

import headerImg from '../../assets/images/computo.jpg';
import webpHeaderImg from '../../assets/images/webp/computo.webp';
import mobileHeaderImg from '../../assets/images/mobile/computo.jpg';

import { CursorContext } from '../../Context/CursorContext';
import { WebPContext } from '../../Context/WebPContext';

import './Computo.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] };

const Computo = ({ size }) => {

  const { setCursorType } = useContext(CursorContext);

  const { webP } = useContext(WebPContext);

  const history = useHistory();
  const location = useLocation();

  const [exit, setExit] = useState(false);

  const frameID = useRef();
  const scrollContainer = useRef();

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBodyHeight();
  }, [size.height, webP]);

  const setBodyHeight = () => {
    sleep(1000).then(() => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`;
    })
  };

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0
  };

  const skewScrolling = () => {
    if (window.location.pathname !== '/computo') {
      cancelAnimationFrame(frameID.current);
      return true;
    }

    //data.current = document.getElementById('root').scrollTop;
    data.current = window.scrollY;

    //Set Current to the scroll position amount
    //data.current = window.scrollY;
    // Set Previous to the scroll previous position
    data.previous += (data.current - data.previous) * data.ease;
    // Set rounded to
    data.rounded = Math.round(data.previous * 100) / 100;

    // Difference between
    //const difference = data.current - data.rounded;
    //const acceleration = difference / size.width;
    //const velocity = +acceleration;
    //const skew = velocity * 7.5;

    //Assign skew and smooth scrolling to the scroll container
    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0)`;
    }

    //loop vai raf
    frameID.current = requestAnimationFrame(() => skewScrolling());
  };

  const goTo = path => {
    if (location.pathname !== path) {
      setExit(true);

      sleep(1000).then(() => {
        setCursorType('regularCursor');
        window.scrollTo(0, 0);
        history.push(path);
      });
    }
  }

  return (
    <div className="homeContainer">

      <motion.div 
        className="inTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: size.height }}
        animate={{ height: 0 }}
        transition={transition}
      />

      <motion.div 
        className="outTransitionOverlay"
        style={{ height: size.height }}
        initial={{ height: 0 }}
        animate={{ height: exit ? size.height : 0 }}
        transition={transition}
      />

      <div ref={scrollContainer} className="categoryInnerContainer">
        <Navbar size={size} goTo={goTo} />


        <div className="computoSubheader">
          <p className="computoSubheaderAuxText">Analizamos financieramente tus requerimientos para la correcta realización de tu proyecto.</p>
          <p className="computoSubheaderText">CÓMPUTO Y</p>
        </div>
        <div className="computoHeader">
          <p className="computoHeaderText">PRESUPUESTO</p>
        </div>

        {webP ? <img src={size.mobile ? mobileHeaderImg : webP === 'webp' ? webpHeaderImg : headerImg} alt="Encabezado" className="graficasHeaderImg" /> : null}

        <p className="graficasAuxText">
          Tanto el cómputo como el presupuesto son dos elementos fundamentales para conocer un proyecto de arquitectura en profundidad. Permite conocer cantidades, costos y posibles beneficios, que ayudarán a moldear el resultado hacia el objetivo deseado.
          <br/>La importancia del rigor a la hora de confeccionar estos dos ítems, son fundamentales para que las conclusiones extraídas sean lo más verosímiles y exactas posibles.
        </p>

        <div className="stepContainer">
          <p className="stepNumber">01</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Análisis</div>
            <div className="stepText">Analizaremos en profundidad la información presentada por el cliente así como el sitio donde se desarrollará el proyecto.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">02</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Cómputo</div>
            <div className="stepText">Se realizará un cómputo de la manera más precisa posible tanto de los materiales como las horas hombres necesarias para llevar a cabo el proyecto.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">03</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Costos</div>
            <div className="stepText">Estudiaremos los costos de diversos proveedores de materiales, gremios y contratistas buscando siempre la mejor relación precio/producto y precio/servicio, respectivamente.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">04</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Gestión</div>
            <div className="stepText">En el caso de requerir gremios específicos se gestionaran los mismos haciendo hincapié, asimismo, en la calidad y los costos de los mismos.</div>
          </div>
        </div>

        <div className="stepContainer">
          <p className="stepNumber">05</p>
          <div className="stepInfoContainer">
            <div className="stepTitle">Resultados</div>
            <div className="stepText">Los resultados se reflejarán de forma desglosada y ordenada para la mejor comprensión del cliente, para que además, pueda tomar una decisión lo más acertada posible.</div>
          </div>
        </div>

        <ContactBanner goTo={goTo} />

        <Footer color={true} />
      </div>
    </div>
  );
};

export default Computo;